<script>
export default {
  methods: {
    subscribeSoloAction() {
      this.$router.push({
        name: 'subscribesoloroute'
      })
    },
    subscribeTeamAction() {
      this.$router.push({
        name: 'subscribeteamroute'
      })
    }
  }
}
</script>

<template>
  <v-banner two-line color="green lighten-5" transition="hide-on-leave">
    <v-avatar slot="icon" color="success" size="40">
      <v-icon color="white"> mdi-hand-peace </v-icon>
    </v-avatar>
    <div class="green--text font-weight-bold mb-2">You are approved!</div>
    <div class="green--text font-weight-medium mb-2">Purchase your Subscription and become searchable…</div>
    <template v-slot:actions="" align="center"><v-spacer /><v-btn medium class="success" @click="subscribeSoloAction">Pay now</v-btn><v-spacer /></template>
  </v-banner>
</template>
